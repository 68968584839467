import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../components/PageWrapper";
import SEO from '../components/SEO';

const Contact = () => { 
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Contact us for more information"
          description="Send us a message and will reply to you within 24 hours"
        />      

        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/book-a-meeting">Book A Meeting</Link>
              </li>
              </ol>
           </nav>
        </div>          
        <div className="d-flex align-items-center pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            {/* Contact Form */}
            <div className="row">
              <div className="msbookContainer col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <iframe title="booking" src='https://outlook.office365.com/owa/calendar/Digma1@digma.io/bookings/' width='100%' height='1700px' scrolling='yes' className="msbook"></iframe>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );            

};
export default Contact;
